import type { getAbilityKey } from "@/game-val/utils.mjs";

interface EffectAttributes {
  /** A label to describe the given effect */
  label: Translation;
  /** Custom format options */
  formatOptions?: Intl.NumberFormatOptions;
  /** Whether this effect should be shown in ability effect summaries. Can either be true, or a record of specific ability keys to hide this statistic for. */
  hidden?: true | PartialRecord<ReturnType<typeof getAbilityKey>, true>;
  /** Whether a lower value is considered better. */
  lessIsBetter?: boolean;
}

export enum AgentAbilityEffects {
  EnemiesBlinded = 0,
  Damage = 1,
  Kills = 2,
  EnemiesDazed = 4,
  EnemiesDisplaced = 5,
  EnemiesRevealed = 6,
  EnemiesBlocked = 7,
  EnemiesNearsighted = 8,
  HealingDone = 9,
  AlliesStimmed = 10,
  EnemiesSlowed = 12,
  DamageReceived = 13,
  EnemiesSpotted = 15,
  EnemiesVulnerabled = 16,
  AlliesBlinded = 17,
  EnemiesDetained = 18,
  EnemiesSuppressed = 19,
  ShotsFired = 25,
  TimeSprinting = 27,
  EnemiesMarked = 35,
  EnemiesSeized = 36,
  AlliesSeized = 51,
}

type AbilityEffects = Record<AgentAbilityEffects | "casts", EffectAttributes>;

export const ABILITY_EFFECTS: AbilityEffects = {
  [AgentAbilityEffects.EnemiesBlinded]: {
    label: ["val:effects.enemiesBlinded", "Enemies Blinded"],
  },
  [AgentAbilityEffects.Damage]: {
    label: ["val:effects.damage", "Damage"],
  },
  [AgentAbilityEffects.Kills]: {
    label: ["val:effects.kills", "Kills"],
  },
  [AgentAbilityEffects.EnemiesDazed]: {
    label: ["val:effects.enemiesDazed", "Enemies Dazed"],
  },
  [AgentAbilityEffects.EnemiesDisplaced]: {
    label: ["val:effects.enemiesDisplaced", "Enemies Displaced"],
  },
  [AgentAbilityEffects.EnemiesRevealed]: {
    label: ["val:effects.enemiesRevealed", "Enemies Revealed"],
  },
  [AgentAbilityEffects.EnemiesBlocked]: {
    label: ["val:effects.enemiesBlocked", "Enemies Blocked"],
  },
  [AgentAbilityEffects.EnemiesNearsighted]: {
    label: ["val:effects.enemiesNearsighted", "Enemies Nearsighted"],
  },
  [AgentAbilityEffects.HealingDone]: {
    label: ["val:effects.healingDone", "Healing Done"],
  },
  [AgentAbilityEffects.AlliesStimmed]: {
    label: ["val:effects.alliesStimmed", "Allies Stimmed"],
  },
  [AgentAbilityEffects.EnemiesSlowed]: {
    label: ["val:effects.enemiesSlowed", "Enemies Slowed"],
  },
  [AgentAbilityEffects.DamageReceived]: {
    label: ["val:effects.damageReceived", "Damage Received"],
    hidden: {
      annihilation: true,
    },
  },
  [AgentAbilityEffects.EnemiesSpotted]: {
    label: ["val:effects.enemiesSpotted", "Enemies Spotted"],
  },
  [AgentAbilityEffects.EnemiesVulnerabled]: {
    label: ["val:effects.enemiesVulnerabled", "Enemies Vulnerabled"],
  },
  [AgentAbilityEffects.AlliesBlinded]: {
    label: ["val:effects.alliesBlinded", "Allies Blinded"],
    lessIsBetter: true,
  },
  [AgentAbilityEffects.EnemiesDetained]: {
    label: ["val:effects.enemiesDetianed", "Enemies Detained"],
  },
  [AgentAbilityEffects.EnemiesSuppressed]: {
    label: ["val:effects.enemiesSuppressed", "Enemies Suppressed"],
  },
  [AgentAbilityEffects.ShotsFired]: {
    label: ["val:effects.shotsFired", "Shots Fired"],
  },
  [AgentAbilityEffects.TimeSprinting]: {
    label: ["val:effects.timeSprinting", "Time Sprinting"],
    formatOptions: {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: "unit",
      unit: "second",
      unitDisplay: "short",
    },
  },
  [AgentAbilityEffects.EnemiesMarked]: {
    label: ["val:effects.enemiesMarked", "Enemies Marked"],
  },
  [AgentAbilityEffects.EnemiesSeized]: {
    label: ["val:effects.enemiesSiezed", "Enemies Seized"],
  },
  [AgentAbilityEffects.AlliesSeized]: {
    label: ["val:effects.alliesSiezed", "Allies Siezed"],
    lessIsBetter: true,
  },
  casts: {
    label: ["val:effects.casts", "Casts"],
  },
} as const;
