import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { formatDuration } from "@/app/util.mjs";
import { buildAccolades } from "@/game-val/BuildAccolades.jsx";
import Abilities from "@/game-val/components/Abilities.jsx";
import DamageBreakdown from "@/game-val/components/DamageBreakdown.jsx";
import MatchPerformance from "@/game-val/components/MatchPerformance.jsx";
import Scoreboard from "@/game-val/components/Scoreboard.jsx";
import StatsBreakdown from "@/game-val/components/StatsBreakdown.jsx";
import { OLD_RANKS } from "@/game-val/constants.mjs";
import { getMatchPosition, getTeamPosition } from "@/game-val/match-utils.mjs";
import {
  getCurrentStatsObj,
  getRecentStatsObj,
} from "@/game-val/postmatch-helpers.mjs";
import { isPrivateProfile } from "@/game-val/profile-utils.mjs";
import RankHSAnalysis from "@/game-val/RankHSAnalysis.jsx";
import {
  getAbilityKills,
  getActOptions,
  getAgentImage,
  getPlayerId,
  getPlayerMatchStats,
  getQueueName,
  isOldRankSystem,
} from "@/game-val/utils.mjs";
import WeaponStatsTable from "@/game-val/WeaponStatsTable.jsx";
import { SharedMatchLayout } from "@/shared/Match.jsx";
import matchRefs from "@/shared/Match.refs.jsx";
import {
  MainColumnsContainer,
  MatchPageContainer,
} from "@/shared/Match.style.jsx";
import Badge, { BadgeList } from "@/shared/PostMatchBadge.jsx";
import { TimeAgo } from "@/shared/Time.jsx";
import { PrivacyError } from "@/util/custom-errors.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import useMediaQuery from "@/util/use-media-query.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ErrorMessage = () => {
  const { t } = useTranslation();
  return (
    <>
      <span className="type-article-headline">
        {t("val:postmatch.profilePrivate", "This profile is private")}
      </span>
      <span className="type-body2">
        {t("val:postmatch.profilePrivateLogin", "Please login to view match.")}
      </span>
    </>
  );
};

// Damage breakdown wraps its text below a certain threshold, which causes the height of the element to change
// These help provide the correct height to the loading skeleton.
const damageBreakdownWrappedHeight = "calc(15.4rem + 146.5px)";
const damageBreakdownUnwrappedHeight = "calc(10.6rem + 146.5px)";

function PostmatchCoaching() {
  const state = useSnapshot(readState);
  const { t } = useTranslation();
  // The damage breakdown text wraps in the flex container at this point, which causes it to be taller than usual
  const isDamageBreakdownTextWrapped = useMediaQuery(
    "@media screen and (max-width: 34.25rem)",
  );

  const route = useRoute((prev, next) => {
    return prev.currentPath === next.currentPath;
  });

  const {
    parameters: [nameTag, actId, matchId],
    searchParams: { queue: queueParam },
  } = route;

  const lowercaseName = (nameTag || "").toLowerCase();
  const profile = state.val.profiles[lowercaseName];
  const matchData = state.val.match[matchId];
  const isPrivate = isPrivateProfile(profile);
  const matchInfo = useMemo(() => {
    if (isPrivate) return new PrivacyError("Profile is private");
    return matchData;
  }, [isPrivate, matchData]);

  const isMatchLoaded = Boolean(matchInfo);

  const queueName = matchInfo?.queue ?? queueParam;
  const isDeathmatch = queueName === "deathmatch";
  const isEscalation = queueName === "ggteam";
  const isAnyDeathMatch = isDeathmatch || isEscalation;

  const matchPlayerWeaponStats =
    state.val?.matchWeaponStats?.[matchId]?.[profile?.puuid];

  const rankStats = useMemo(() => {
    if (!state.val?.rankweapons) return null;
    const filteredStats = {};
    Object.entries(state.val?.rankweapons).forEach(([key, obj]) => {
      if (obj?.damageStats) filteredStats[key] = obj;
    });
    return filteredStats;
  }, [state.val?.rankweapons]);

  const currentPlayerStats = getPlayerMatchStats(matchInfo, profile);

  const valConstants = state.val?.content;
  const seasonIdValues = getActOptions(valConstants?.acts);
  const actAndEp = seasonIdValues.find((s) => s.value === actId)?.text[2];
  const oldRankSystem = isOldRankSystem(actAndEp?.ep);
  const ranks = oldRankSystem ? OLD_RANKS : valConstants?.ranks;
  const competitiveTier = currentPlayerStats?.tier ?? null;

  const tierName = competitiveTier
    ? ranks?.find((r) => r.position === competitiveTier)?.tier?.toLowerCase()
    : null;

  const matchPosition =
    matchInfo &&
    currentPlayerStats &&
    matchInfo.playerMatches &&
    getMatchPosition(matchInfo.playerMatches, currentPlayerStats);
  const teamPosition =
    matchInfo &&
    currentPlayerStats &&
    matchInfo.playerMatches &&
    getTeamPosition(matchInfo.playerMatches, currentPlayerStats);

  const latestPlayerStats =
    state.val.latestPlayerStats[profile?.puuid]?.[matchInfo?.queue]?.[0];

  //division stats for the division the current player is in
  const userDivisionStats = rankStats?.[tierName];
  const accolades = useMemo(() => {
    if (!matchInfo || !profile || matchInfo instanceof Error) return {};
    const playerId = getPlayerId(profile);

    return buildAccolades({
      t,
      divisionStats: userDivisionStats,
      radiantDivisionStats: rankStats?.radiant,
      queue: queueName,
      playerId,
      teamId: currentPlayerStats?.teamId,
      teamPosition,
      matchData: matchInfo,
      isAnyDeathMatch: isAnyDeathMatch,
      isSpikeRush: false,
      isEscalation: false,
    });
  }, [
    t,
    userDivisionStats,
    rankStats,
    queueName,
    matchInfo,
    profile,
    currentPlayerStats,
    teamPosition,
    isAnyDeathMatch,
  ]);

  const abilityKillData = currentPlayerStats
    ? getAbilityKills(currentPlayerStats)
    : null;

  const playersMatchStats = {};
  for (const player of Object.values(matchInfo?.playerMatches || [])) {
    playersMatchStats[player?.id] = player;
  }

  const divisionWeaponData = state.val?.divisionWeaponStats;

  const isVictory = currentPlayerStats?.won;
  const dot_str = "·";

  const agentId = currentPlayerStats?.agent?.uuid;

  const agentKey =
    valConstants?.agents &&
    valConstants.agents
      .find(
        (agentObj) => agentObj.uuid.toLowerCase() === agentId?.toLowerCase(),
      )
      ?.name?.toLowerCase()
      ?.replaceAll("/", "");

  const matchDuration = matchInfo?.gameLength
    ? formatDuration(matchInfo.gameLength, "mm:ss")
    : "—";

  const currentMatchStats = currentPlayerStats
    ? getCurrentStatsObj(currentPlayerStats)
    : null;
  const recentMatchStats = latestPlayerStats
    ? getRecentStatsObj(latestPlayerStats)
    : null;

  const getHeadertitle = () => {
    if (isDeathmatch && matchPosition)
      return t("common:ordinalPlace", "{{place, ordinal}} Place", {
        place: matchPosition,
      });
    return isVictory
      ? t("common:victory", "Victory")
      : t("common:defeat", "Defeat");
  };

  return (
    <SharedMatchLayout
      match={matchInfo}
      ErrorMessage={isPrivate ? ErrorMessage : null}
      className="postmatch-header"
      title={matchInfo ? getHeadertitle() : null}
      image={getAgentImage(agentKey, "header")}
      imageLink={
        profile
          ? `/valorant/profile/${profile?.gameName}-${profile?.tagLine}`
          : null
      }
      handleLoading={false}
      underTitle={
        isMatchLoaded ? (
          <>
            <span className="header-desc">
              {t(`val:queues.${queueName}`, getQueueName(queueName))}
            </span>
            <span className="header-desc">{dot_str}</span>
            {matchDuration}
            <span className="header-desc">{dot_str}</span>
            <TimeAgo date={matchInfo?.gameStart} />
          </>
        ) : (
          <Card loading style={{ height: "4.5rem" }} />
        )
      }
      badges={
        (accolades?.fancy || []).length ? (
          <BadgeList className="span-all">
            {(accolades?.fancy || []).slice(0, 10).map((accolade) => (
              <Badge
                key={accolade.title}
                accolade={{
                  ...accolade,
                  description: [accolade.description],
                }}
              />
            ))}
          </BadgeList>
        ) : null
      }
    >
      <MatchPageContainer>
        <MainColumnsContainer
          className={matchRefs.columnsContainerClassName?.()}
        >
          <div className="span-all">
            <MatchPerformance
              nameTag={lowercaseName}
              abilityKillData={abilityKillData}
              agent={agentKey}
              matchId={matchId}
              queue={queueName}
            />
          </div>
          <div className="span-1">
            {rankStats &&
            ranks &&
            currentMatchStats &&
            recentMatchStats &&
            divisionWeaponData ? (
              <RankHSAnalysis
                recentStats={recentMatchStats}
                matchStats={currentMatchStats}
                matchInfo={matchInfo}
                rankWeaponStats={rankStats}
                divisionWeaponStats={divisionWeaponData}
                rankObjs={ranks}
              />
            ) : !isMatchLoaded ? (
              <Card loading style={{ height: "32.5rem" }} />
            ) : null}
          </div>
          <div className="span-2">
            {matchRefs.InContentBanner && <matchRefs.InContentBanner />}
            {currentMatchStats && recentMatchStats ? (
              <DamageBreakdown matchId={matchId} profile={profile} />
            ) : !isMatchLoaded ? (
              <Card
                loading
                style={{
                  height: isDamageBreakdownTextWrapped
                    ? damageBreakdownWrappedHeight
                    : damageBreakdownUnwrappedHeight,
                }}
              />
            ) : null}

            {currentPlayerStats &&
            latestPlayerStats &&
            currentMatchStats &&
            recentMatchStats ? (
              <Card padding="0">
                <StatsBreakdown matchId={matchId} profile={profile} />
              </Card>
            ) : !isMatchLoaded ? (
              <Card loading style={{ height: "47rem" }} />
            ) : null}

            {((matchPlayerWeaponStats && matchPlayerWeaponStats.length) ||
              state?.volatile?.valLastMatch) &&
            recentMatchStats ? (
              <Card padding="0">
                <WeaponStatsTable
                  agentName={agentKey}
                  nameTag={nameTag}
                  matchId={matchId}
                  isDeathmatch={isDeathmatch}
                  matchWeaponStats={matchPlayerWeaponStats}
                />
              </Card>
            ) : !isMatchLoaded ? (
              <Card loading style={{ height: "10.5rem" }} />
            ) : null}

            {!isDeathmatch &&
            matchInfo &&
            currentPlayerStats &&
            abilityKillData ? (
              <Card padding="0">
                <Abilities matchId={matchId} profile={profile} />
              </Card>
            ) : !isMatchLoaded ? (
              <Card loading style={{ height: "13.375rem" }} />
            ) : null}

            {matchInfo &&
            playersMatchStats &&
            currentPlayerStats &&
            valConstants ? (
              <Card padding="0">
                <Scoreboard matchId={matchId} profile={profile} />
              </Card>
            ) : !isMatchLoaded ? (
              <Card loading style={{ height: "39rem" }} />
            ) : null}
          </div>
        </MainColumnsContainer>
      </MatchPageContainer>
    </SharedMatchLayout>
  );
}

export function meta([userName, _, _matchId]) {
  return {
    title: [
      "val:meta.postmatch.title",
      "{{userName}}'s VALORANT Match Performance",
      { userName: userName },
    ],
    description: [
      "val:meta.postmatch.description",
      "View {{userName}}'s VALORANT match statistics and how they performed.",
      { userName: userName },
    ],
  };
}

export default PostmatchCoaching;
