import type { DeepReadonly } from "ts-essentials";

import type { ValorantRankBenchmark } from "@/data-models/valorant-coaching-benchmark-data.mjs";
import type { ValorantCoachingData } from "@/data-models/valorant-coaching-data.mjs";
import type { MatchStats } from "@/game-val/postmatch-helpers.mjs";
import type { PlayerMatch } from "@/game-val/utils.mjs";
import type {
  StatPerformance,
  StatPerformanceParams,
} from "@/util/coaching.mjs";
import rangeBucket from "@/util/range-bucket.mjs";

export interface PlayerDataMeta {
  tier: string;
  matchId: string;
  playerId: string | null;
  puuid: string | null;
  queue: string;
}

export interface PlayerData {
  meta: DeepReadonly<PlayerDataMeta>;
  playerStats: DeepReadonly<PlayerMatch>;
  matchStats: DeepReadonly<MatchStats>;
  extraStats: DeepReadonly<ValorantCoachingData["extraStats"] | null>;
  rawMatch: DeepReadonly<ValorantCoachingData["rawMatch"] | null>;
}

export interface BenchmarkStat {
  avg: number;
  stddev: number;
}

export interface CoachingStat {
  /**
   * A function returning the player's value for this stat from the provided data. If this returns null, the stat will be excluded from calculations.
   *
   * @param data Match data that can be used to calculate a variety of stats @see {@link PlayerData}
   * @returns A number to be used in calculations, or null if the stat is unavailable for calculation.
   */
  getPlayerVal: (data: PlayerData) => number | null;
  /**
   * A function that returns the benchmark data the supplied value should be compared against.
   *
   *
   * @param data
   * @returns An object with the mean and standard deviation to compare the playerVal against.
   */
  getBenchmark: (data: ValorantRankBenchmark) => BenchmarkStat;
  /**
   * The calculated performance for this specific stat.
   *
   * @see {@link StatPerformance}
   */
  performance?: StatPerformance;
  /** An overriding lower bound for this statistic */
  min?: number;
  /** An overriding upper bound for this statistic */
  max?: number;
  /** Whether a lower value should be considered better. @default false */
  lowerIsBetter?: boolean;
  /** A label to describe this statistic */
  label: Translation;
  /** A more detailed description for this statistic. */
  description: Translation;
  /** Any formatting options that should be applied to this. @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat Intl.NumberFormat documenation} */
  formatOptions?: Intl.NumberFormatOptions;
  /** Whether the stat should display the score rather than the calculated player value. This will still use any formatOptions specified */
  displayScore?: boolean;
  /** Any overrides that should be applied to the statPerformance function. @see {@link StatPerformanceParams} */
  performanceOverrides?: Partial<StatPerformanceParams>;
}

export interface CoachingCategory {
  title: Translation;
  score: number;
  stats: CoachingStat[];
}

export interface PopulatedCoachingStat
  extends Omit<CoachingStat, "performance"> {
  performance: NonNullable<CoachingStat["performance"]>;
}

export interface PopulatedCoachingCategory
  extends Omit<CoachingCategory, "stats"> {
  stats: PopulatedCoachingStat[];
}

export const SCORE_MIN = 0;
export const SCORE_MAX = 1;

export const MAX_STATS_PER_CATEGORY = 3;

export const SHOOTING_TITLE = [
  "val:coaching.categoryCombat",
  "Combat",
] as const satisfies Translation;
export const ECONOMY_TITLE = [
  "val:coaching.categoryEconomy",
  "Economy",
] as const satisfies Translation;
export const ABILITIES_TITLE = [
  "val:coaching.abilities",
  "Abilities",
] as const satisfies Translation;

export const FULL_BUY_WEAPONS = [
  "9C82E19D-4575-0200-1A81-3EACF00CF872", // Vandal
  "EE8E8D15-496B-07AC-E5F6-8FAE5D4C7B1A", // Phantom
  "63E6C2B6-4A8E-869C-3D4C-E38355226584", // Odin
  "A03B24D3-4319-996D-0F8C-94BBFBA1DFC7", // Operator
];

/**
 * The number of rounds per half for a given queue.
 */
export const ROUNDS_PER_HALF = {
  spikerush: 3,
  swiftplay: 4,
  unrated: 12,
  competitive: 12,
  premier: 12,
} as const;

export const LOADOUT_VALUE_BUCKET = rangeBucket([
  [0, "ECO"],
  [2900, "FULL"],
]);
