import { css } from "goober";

export const cardContainer = css`
  height: var(--base-height);
  overflow: hidden scroll;

  &::-webkit-scrollbar {
    width: 0;
  }
  > * {
    position: relative;
  }

  .content {
    padding-block-end: var(--sp-4);
  }

  header {
    padding: var(--sp-6) var(--sp-6) 0;
  }

  .ability {
    display: grid;
    gap: var(--sp-1);
    padding: var(--sp-3) var(--sp-6);

    &:not(:last-child) {
      border-bottom: 1px solid var(--card-inset-border);
    }
  }

  .ability-meta {
    display: flex;
    align-items: center;
    gap: var(--sp-2);
  }

  .ability-stat {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .ability-icon {
    width: var(--sp-6);
    height: var(--sp-6);
    padding: var(--sp-0_5);
    border: 2px solid hsl(var(--agent-color));
    border-radius: 50%;
  }
`;
