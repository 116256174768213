import { css, styled } from "goober";

export const performanceWrapper = css`
  .card-frame {
    min-height: var(--base-height, 362px);
  }
`;

export const bgImg = css`
  position: absolute;
  top: 0;
  left: 50%;
  translate: -30% -8%;
  mask-image: radial-gradient(
    circle at top center,
    hsl(0deg 0% 0% / 40%),
    transparent 70%
  );
  background-color: var(--agent-color);
  width: 200%;
  transform: var(--agent-transform);
`;

export const RankIcon = styled("div")`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  margin: 0 var(--sp-1);
`;
