import { COACHING_STATS } from "@/game-val/coaching-stats.mjs";
import type { CoachingCategory } from "@/game-val/constants/coaching-constants.mjs";
import {
  ECONOMY_TITLE,
  SHOOTING_TITLE,
} from "@/game-val/constants/coaching-constants.mjs";

export const COACHING_CATEGORIES: Record<string, CoachingCategory> = {
  combat: {
    title: SHOOTING_TITLE,
    score: 0,
    stats: [
      COACHING_STATS.headshotPercent,
      COACHING_STATS.reactionTime,
      COACHING_STATS.score,
    ],
  },
  economy: {
    title: ECONOMY_TITLE,
    score: 0,
    stats: [COACHING_STATS.avgSpendNonFL, COACHING_STATS.teamBuyCoordination],
  },
};
