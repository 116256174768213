import type { CoachingStat } from "@/game-val/constants/coaching-constants.mjs";
import { FULL_BUY_WEAPONS } from "@/game-val/constants/coaching-constants.mjs";
import { safeDivide } from "@/game-val/utils.mjs";
import {
  hasFullBuyWeapon,
  isFullBuyableRound,
} from "@/game-val/utils/coaching.mjs";

export const COACHING_STATS: Record<string, CoachingStat> = {
  headshotPercent: {
    getPlayerVal(data) {
      const headshotPercent = data?.matchStats?.hitPercents?.headshotPercent;
      return safeDivide(headshotPercent, 100);
    },
    getBenchmark(data) {
      return data?.overall?.headshotAccuracy;
    },
    label: ["val:coachingStats.headshotPercent", "Headshot %"],
    description: [
      "val:coachingStats.headshotPercentDescription",
      "The percentage of your hit shots that were headshots.",
    ],
    formatOptions: {
      style: "percent",
      maximumFractionDigits: 1,
      minimumFractionDigits: 0,
    },
  },
  score: {
    getPlayerVal(data) {
      return safeDivide(
        data?.playerStats?.score,
        data?.playerStats?.roundsPlayed,
      );
    },
    getBenchmark(data) {
      return data?.perRound?.score;
    },
    label: ["val:coachingStats.score", "Combat Score"],
    description: [
      "val:coachingStats.scoreDescription",
      "Your overall combat score, which is calculated by Riot based on a combination of factors including damage dealt, kills, and assists.",
    ],
    formatOptions: {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    },
  },
  hits: {
    getPlayerVal(data) {
      const headshots = data?.playerStats?.headshots ?? 0;
      const bodyshots = data?.playerStats?.bodyshots ?? 0;
      const legshots = data?.playerStats?.legshots ?? 0;
      return safeDivide(
        headshots + bodyshots + legshots,
        data?.playerStats?.roundsPlayed,
      );
    },
    getBenchmark(data) {
      return data?.perRound?.totalHits;
    },
    label: ["val:coachingStats.hits", "Shots Hit / Round"],
    description: [
      "val:coachingStats.hitsDescription",
      "The average number of shots hit per round.",
    ],
    formatOptions: {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    },
  },
  damage: {
    getPlayerVal(data) {
      return data?.playerStats?.damagePerRound;
    },
    getBenchmark(data) {
      return data?.perRound?.damageDealt;
    },
    label: ["val:coachingStats.damage", "Damage / Round"],
    description: [
      "val:coachingStats.damageDescription",
      "The average of damage done to enemies per round.",
    ],
    formatOptions: {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    },
  },
  reactionTime: {
    getPlayerVal(data) {
      return data?.extraStats?.reactionTime ?? null;
    },
    getBenchmark(data) {
      return data?.overall?.reactionTime;
    },
    label: ["val:coachingStats.reactionTime", "Reaction Time"],
    description: [
      "val:coachingStats.reactionTimeDescription",
      "The amount of time between when an enemy became visible, and you landed your first shot.",
    ],
    formatOptions: {
      style: "unit",
      unit: "millisecond",
      unitDisplay: "short",
    },
    lowerIsBetter: true,
  },
  avgSpendNonFL: {
    getPlayerVal(data) {
      const rawMatchStats = data?.rawMatch;
      if (!rawMatchStats) return null;

      const { roundResults } = rawMatchStats;

      if (!roundResults || roundResults?.length < 1) return null;

      const nonFullBuys = roundResults
        .map((rr) => {
          if (!rr) return null;

          // Exclude the first two rounds in each half, but not sudden death
          if (!isFullBuyableRound(rr.roundNum, data.meta.queue)) return null;

          const { playerEconomies } = rr;

          // No economy to iterate over, return null
          if (!playerEconomies || playerEconomies.length < 1) return null;

          // Grab current player's economy
          const economy = playerEconomies.find(
            (pe) => pe.subject === data.meta.playerId,
          );

          // Full buy round, not needed to calculate stat
          if (!economy || hasFullBuyWeapon(economy)) return null;

          return economy;
        })
        .filter(Boolean);

      const nonFullBuySpendTotal = nonFullBuys.reduce(
        (acc, econ) => acc + (econ?.spent ?? 0),
        0,
      );

      return safeDivide(nonFullBuySpendTotal, nonFullBuys.length);
    },
    getBenchmark(data) {
      return data?.perRound?.nfbSpend;
    },
    label: ["val:coachingStats.nfbAvgSpend", "Saving Index"],
    description: [
      "val:coachingStats.nfbAvgSpendDescription",
      "How well you budgeted your credits on rounds where you didn't buy a full-buy weapon.",
    ],
    formatOptions: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    lowerIsBetter: true,
    displayScore: true,
    performanceOverrides: {
      upperBoundStdevMultiplier: 1.2,
    },
  },
  teamBuyCoordination: {
    getPlayerVal(data) {
      const rawMatchStats = data?.rawMatch;
      if (!rawMatchStats) return null;

      const { players, roundResults } = rawMatchStats;

      if (!roundResults || roundResults.length < 1) return null;

      const teams = players.reduce<{
        red: string[];
        blue: string[];
        playerTeam: "red" | "blue" | null;
      }>(
        (acc, player) => {
          const teamId = player.teamId.toLowerCase() as "red" | "blue";
          acc[teamId].push(player.subject);
          if (player.subject === data.meta.playerId) acc.playerTeam = teamId;
          return acc;
        },
        {
          red: [],
          blue: [],
          playerTeam: null,
        },
      );

      if (!teams.playerTeam) return null;

      const teamMembers = teams[teams.playerTeam];

      const playerBuyCoordination = roundResults
        .map((rr) => {
          if (!rr) return null;

          if (!isFullBuyableRound(rr.roundNum, data.meta.queue)) return null;

          const { playerEconomies } = rr;

          if (!playerEconomies || playerEconomies.length < 1) return null;

          const coordination = playerEconomies.reduce(
            (acc, pe) => {
              if (!teamMembers.includes(pe.subject)) return acc;

              const isPlayer = pe.subject === data.meta.playerId;
              const isFullBuy = FULL_BUY_WEAPONS.includes(pe.weapon);

              if (isFullBuy) acc.fullBuyCount++;
              if (isPlayer) acc.didPlayerFullBuy = isFullBuy;

              return acc;
            },
            {
              fullBuyCount: 0,
              didPlayerFullBuy: false,
            },
          );

          return (
            coordination.fullBuyCount >= 3 === coordination.didPlayerFullBuy
          );
        })
        .filter((rr) => rr !== null);

      const coordinatedRoundCount = playerBuyCoordination.reduce(
        (acc, isCoordinated) => (isCoordinated ? acc + 1 : acc),
        0,
      );

      return safeDivide(coordinatedRoundCount, playerBuyCoordination.length);
    },
    getBenchmark(data) {
      return data?.overall?.ecoConform;
    },
    label: [
      "val:coachingStats.teamBuyCoordination",
      "Team Buying Coordination",
    ],
    description: [
      "val:coachingStats.teamBuyCoordinationDescription",
      "How often your team full bought, or saved together.",
    ],
    formatOptions: {
      style: "percent",
      maximumFractionDigits: 1,
      minimumFractionDigits: 0,
    },
    performanceOverrides: {
      upperBoundStdevMultiplier: 1.2,
    },
  },
};
